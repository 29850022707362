let domain = (window.location.hostname).replace('panel.', '');

if (process.env.VUE_APP_BASE_URI) {
    domain = process.env.VUE_APP_BASE_URI;
}

let apiUri = domain;

export const config = {
    BASE_URI: 'https://' + domain,
    APP_BASE_URI:  'https://panel.' + domain,
    API_URI: 'https://'+ apiUri +'/api/v1',
}

export default config;
