import Vue from 'vue'
import Router from 'vue-router'
import ApiService from "@/services/api.service";
import JwtService from "@/services/jwt.service";
import store from "@/store";

// Layouts
import PageLayout from '../app/Layouts/PageLayout'

// Pages
import DashboardIndex from '../app/Pages/Dashboard/Index'

// Error
import Error404 from '../app/Pages/Errors/Error404'
import ErrorAccess from '../app/Pages/Errors/ErrorAccess'
import Maintenance from '../app/Pages/Errors/Maintenance'

// Routes Group
import AccountRoutes from './account'
import AuthRoutes from './auth'
import PluginRoutes from './plugins'
import SystemRoutes from './system'
import ToolRoutes from './tools'
import DesignRoutes from './design'
import CatalogRoutes from './catalog'
import CmsRoutes from './cms'

// Init
Vue.use(Router);

/**
 * Vue router
 *
 * @type {VueRouter}
 */
const router = new Router({
    mode: 'history',
    routes: [
        AccountRoutes,
        AuthRoutes,
        PluginRoutes,
        SystemRoutes,
        ToolRoutes,
        DesignRoutes,
        CatalogRoutes,
        CmsRoutes,
        {
            path: '/',
            name: 'app',
            redirect: '/auth/login',
            component: PageLayout,
            children: [
                {
                    path: 'dashboard',
                    name: 'app.dashboard.index',
                    component: DashboardIndex,
                    meta: {
                        auth: true,
                        title: 'Dashboard',
                        layout: 'full',
                    }
                }
            ],
        },
        {
            path: '/errors/access',
            name: 'app.errors.access.index',
            component: ErrorAccess,
            meta: {
                layout: 'content',
                title: 'Upss! Access Denied!',
            }
        },
        {
            path: '/maintenance',
            name: 'app.errors.maintenance.index',
            component: Maintenance,
            meta: {
                layout: 'content',
                title: 'Bakım Çalışması',
            }
        },
        {
            path: '*',
            name: 'app.errors.404.index',
            component: Error404,
            meta: {
                layout: 'content',
                title: 'Upss! Page Not Found',
            }
        },
    ]
});

router.afterEach(() => {
    ApiService.setHeader()
});

router.beforeEach((to, from, next) => {

    ApiService.setHeader();

    console.log(to.name)

    if (to.meta && to.meta.title) {
        document.title = to.meta.title + ' | Ribrit Teknoloji';
    } else {
        document.title = 'Ribrit Teknoloji';
    }

    if ((to.name).search('app.errors.maintenance') >= 0) {
        return next();
    }

    if (!JwtService.getToken()) {
        if ((to.name).search('app.auth.') < 0) {
            return window.location = '/auth/login';
        }
    } else {
        if ((to.name).search('app.auth.') >= 0) {
            return window.location = '/dashboard';
        }
    }

    if (store.getters.account) {
        if (store.getters.account.role !== 'admin' && from.meta.access === 'admin') {
            return window.location = '/errors/access';
        }
    }

    return next();
});

export default router;
