<template>
    <form-box :title="$t('cms.seo.file.title')" back_link="/cms/seo/files" :store="store" :loading="loading">
        <el-form ref="form" :model="form">

            <el-form-item label="Baslik">
                <el-input v-model="form.title" placeholder="Baslik"/>
            </el-form-item>

            <el-form-item label="Dosya Adi">
                <el-input v-model="form.name" placeholder="Dosya Adi"/>
            </el-form-item>

            <el-form-item label="Dosya Icerigi">
                <el-input v-model="form.content" placeholder="Dosya Icerigi" type="textarea" rows="20"/>
            </el-form-item>
        </el-form>
    </form-box>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import {STORE_CRUD_ITEM} from "@/store/actions.type";
import {mapGetters} from "vuex";

export default {
    name: 'CrudCreate',
    data() {
        return {
            path: 'cms/seo/files',
            pageLoading: false,
            loading: false,
            submitted: false,
            form: {
               //
            }
        }
    },
    validations: {
        form: {
            //
        }
    },
    computed: mapGetters(['ui']),
    methods: {
        store() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;
            this.form.path = this.path

            this.$store
                .dispatch(STORE_CRUD_ITEM, this.form)
                .then((response) => {
                    this.submitted = false;

                    setTimeout(() => {
                        this.loading = false;
                        this.goRoute('/cms/seo/files/' + response.id)
                    }, 1000)
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        },
    }
}
</script>
