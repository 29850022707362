export const tr = {
    error: {
        title: 'Oops we can’t find what you are looking for :(',
        access: 'Üzgünüm bu sayfaya erişim yetkiniz bulunmuyor :(',
        maintenance_title: 'Size daha iyi hizmet verebilmek için, servislerimizi güncelliyoruz...',
    },

    empty: {
        search: 'Aramanıza uygun sonuç bulunamadı.!',
        address: 'Adres bulunmadı.',
    },

    notification: {
        warning_title: 'Dikkat!',
        destroy: 'Seçmiş olduğunuz veri kalıcı olarak silinecek. Onaylıyor musunuz?',
        destroy_cancel: 'Silme işlemi iptal edildi.',
        sending_cancel: 'Gönderim işlemi iptal edildi.',
    },

    menu: {
        dashboard: 'Dashboard',
        categories: 'Kategoriler',
        contents: 'İçerikler',
        cms: 'İçerik Yönetimi',
        pages: 'Sayfalar',
        posts: 'Blog',
        faqs: 'Sıkça Sorulan Sorular',
        events: 'Etkinlikler',
        references: 'Referanslar',
        seo: 'Seo',
        seo_links: 'Seo Linkler',
        seo_files: 'Seo Dosyalar',
        seo_redirects: '301 Yönlendirmeleri',
        catalog: 'Ürün Yönetimi',
        products: 'Ürünler',
        brands: 'Markalar',
        features: 'Özellikler',
        design: 'Tasarım',
        themes: 'Tema',
        sliders: 'Sliderlar',
        menus: 'Menüler',
        system: 'Sistem',
        settings: 'Ayarlar',
        users: 'Kullanıcılar',
        notes: 'Notlar',
        excel_exports: 'Excel Çıktıları',
        forms: 'Form Mesajları',
        plugins: 'Eklentiler',
        file_manager: 'Dosya Yöneticisi',
    },

    auth: {
        login_title: 'Hoş geldiniz!',
        login_description: 'E-Posta Adresiniz ve Şifreniz ile giriş yapabilirsiniz.',
        remember_me: 'Beni Hatırla',
        login: 'Giriş Yap',
    },

    account: {
        menu: {
            settings: 'Ayarlar',
            logout: 'Çıkış Yap!',
            head_1: 'Hesap Bilgilerim',
            me: 'Profil Bilgilerim',
            password: 'Şifre',
            addresses: 'Adreslerim',
        },

        me_title_1: 'Profil Bilgileri',
        me_title_2: 'Profil Fotoğrafı',
        password_title: 'Şifre Bilgileriniz',
        notification_title: 'Bildirim Ayarları',
        address_title: 'Adres Bilgilerim',
        address_show: 'Adres Bilgileri',
        add_new_address: 'Yeni Adres Ekle',
    },

    dashboard: {
        monthly_stat_title: '{month} Ayı Form Mesajları',
        last_messages: 'Son Eklenen Form Mesajları',
        page_view: 'Sayfa Gösterimi',
        page_count: 'Sayfa Sayısı',
        message_count: 'Form Mesajları',
    },

    tools: {
        notes: {
            title: 'Notlar',
            show: 'Not Bilgileri',
        },

        activity: {
            activity: 'Aktivite',
            title: 'Aktivite Geçmişi'
        },

        form_messages: {
            title: 'Form Mesajları',
            create: 'Mesaj Oluştur',
            show: 'Form Mesajı'
        },
    },

    design: {
        menus: {
            title: 'Menüler',
            create: 'Menü Oluştur',
            show: 'Menü'
        },
    },

    cms: {
        aliases: {
            pages: 'Sayfalar',
            posts: 'Blog',
            faqs: 'Sıkça Sorulan Sorular',
        },
        categories: {
            create: 'Yeni Kategori Ekle',
            show: 'Kategori Bilgileri',
            title: 'Kategoriler',
            search: 'Başlık bilgisi ile ara...',
        },
        contents: {
            create: 'Yeni İçerik Ekle',
            show: 'İçerik Bilgileri',
            title: 'İçerikler',
            search: 'Başlık bilgisi ile ara...',
        },
        seo: {
            link: {
                create: 'Yeni Seo Link Ekle',
                show: 'Seo Link Bilgileri',
                title: 'Seo Linkler',
                search: 'Başlık bilgisi ile ara...',
            },
            redirect: {
                create: 'Yeni 301 Yönlendirmesi Ekle',
                show: '301 Yönlendirme Bilgileri',
                title: '301 Yönlendirmeleri',
                search: 'Başlık bilgisi ile ara...',
            },
            file: {
                create: 'Yeni Seo Dosyasi Ekle',
                show: 'Seo Dosya Bilgileri',
                title: 'Seo Dosyalari',
                search: 'Başlık bilgisi ile ara...',
            }
        },
    },

    catalog: {
        brands: {
            create: 'Yeni Marka Ekle',
            show: 'Marka Bilgileri',
            title: 'Markalar',
            search: 'Başlık bilgisi ile ara...',
        },
        products: {
            search: 'ürün adını yazarak ara..',
            create: 'Yeni Ürün Ekle',
            show: 'Ürün Bilgileri',
            sub_title_global: 'Genel',
            sub_title_prices: 'Fiyatlandırma',
            sub_title_accounting: 'Muhasebe',
        },
    },

    system: {
        settings: {
            service: 'Servis Ayarları',
        },
        users: {
            title: 'Kullanıcılar',
            search: 'ad, telefon numarasi veya eposta ile ara...',
            create: 'Yeni Kullanıcı Ekle',
            show: 'Kullanıcı Bilgileri',
            update_password_warning: 'Kullanıcının şifresini değiştirmek istemiyorsanız, şifre alanını boş bırakın.'
        },
        definitions: {
            create: 'Yeni Tanım Ekle',
            show: 'Tanım Bilgileri',
            title: 'Tanımlar',
            search: 'Başlık bilgisi ile ara...',
        },
        statuses: {
            create: 'Yeni Durum Ekle',
            show: 'Durum Bilgileri',
            title: 'Durumlar',
            search: 'Başlık bilgisi ile ara...',
        },
        exports: {
            title: 'Excel Çıktıları',
            show: 'Cikti Bilgileri',
            columns: 'Çıktıda Gösterilecek Alanlar',
            download_btn: 'Koşula Bağlı Excel Çıktısı Oluştur',
        }
    },

    // inputs
    inputs: {
        company: 'Kurum',
        your_email: 'E-Posta Adresiniz',
        your_password: 'Şifreniz',
        your_new_password: 'Yeni Şifreniz',
        your_new_password_repeat: 'Yeni Şifreniz (Tekrar)',
        your_name: 'Adınız & Soyadınız',
        your_phone: 'Telefon Numaraniz',
        sms: 'Sms',
        email: 'E-Posta',
        mobile: 'Mobil',
        main_address: 'Varsayılan İletişim Adresi',
        address: 'Adres',
        address_title: 'Adres Başlığı',
        first_name: 'Ad',
        last_name: 'Soyad',
        identification_no: 'T.C. Kimlik No.',
        phone: 'Telefon',
        country: 'Ülke',
        city: 'Şehir',
        town: 'İlçe',
        address_1: 'Adres 1',
        address_2: 'Adres 2',
        company_name: 'Firma Adı',
        tax_no: 'Firma Vergi No',
        tax_center: 'Vergi Merkezi',
        zip_code: 'Posta Kodu',
        geo_location: 'Konum',
        name: 'Ad & Soyad',
        password: 'Şifre',
        department: 'Departman',
        title: 'Başlık',
        bg_color: 'Arka Plan Rengi',
        text_color: 'Yazı Rengi',
        wait_status: 'Bekliyor',
        success_status: 'Tamamlandı',
        cancel_status: 'İptal Edildi',
        fail_payment_status: 'Hatalı Ödeme',
        default_payment_type: 'Varsayılan Ödeme Servisi',
        default_sms_service: 'Varsayılan Sms Servisi',
        default_accounting_type: 'Varsayılan Muhasebe Servisi',
        default_delivery_type: 'Varsayılan Kargo Servisi',
        company_real_name: 'Firma Adı',
        company_owner: 'Firma Sahibi',
        company_website: 'Firma Web Site',
        company_email: 'Firma E-Posta',
        company_gsm: 'Firma Gsm',
        company_whatsapp: 'Firma Whatsapp Telefon Numarası',
        company_whatsapp_link: 'Firma Whatsapp Linki',
        company_phone: 'Firma Telefon',
        company_fax: 'Firma Fax',
        company_address: 'Firma Adres',
        classroom: 'Sınıf',
        tags: 'Etiketler',
        education_year: 'Eğitim Yılı',
        education_period: 'Eğitim Dönemi',
        education_quantity: 'Kontenjan',
        education_at: 'Eğitim Başlangıç/Bitiş Tarihi',
        education_start_at: 'Eğitim Başlangıç Tarihi',
        education_end_at: 'Eğitim Bitiş Tarihi',
        tax: 'Vergi Sınıfı',
        lesson_quantity: 'Toplam Ders Saati',
        price: 'Fiyat',
        education_price: 'Eğitim Fiyatı',
        lesson_per_price: 'Ders Birim Fiyatı',
        suggestion_products: 'Birlikte verilecek ücretsiz ürünler',
        stock_quantity: 'Stok Adeti',
        draft_type: 'Gönderim Türü',
        subject: 'Konu',
        message: 'Mesaj',
        sort: 'Sıralama',
        discount_rate: 'İndirim Oranı',
        discount_rate_type: 'İndirim Türü',
        extra_discount_rate: 'Ekstra İndirim Oranı',
        rate: 'Oran',
        start_at: 'Başlangıç Tarihi',
        end_at: 'Bitiş Tarihi',
        loyalty_year: 'Minimum Bağlılık',
        is_active: 'Etkinleştir',
        active: 'Aktif',
        passive: 'Pasif',
        is_single: 'Bunun dışında başka indirim varsa kullanma',
        is_sibling: 'Birden fazla kardeş olan öğrencilerde kullan.',
        is_education_price: 'Sadece sınıf eğitim ücretlerinde kullan',
        classrooms: 'Sınıflar',
        payment_methods: 'Ödeme Yöntemleri',
        companies: 'Kurumlar',
        special_statuses: 'Özel Durumlar',
        special_status: 'Özel Durum',
        jobs: 'Meslekler',
        job: 'Meslek',
        campaign_count: 'Teklifte Önerilecek Max. Kampanya Sayısı',
        campaign_worker_is_usage: 'Çalışanlar Teklif Sırasında Kampanya Düzenleyebilir.',
        brand: 'Marka',
        hear_us: 'Bizi Nereden Duydunuz?',
        contact_message: 'Görüşme Detayı',
        offer_phone: 'Görüşem yaptığınız kişinin telefon numarası.',
        payment_type: 'Ödeme Yöntemi',
        first_payment_date: 'Ilk Ödeme Tarihi',
        expiry_at: 'Geçerlilik Tarihi',
        single_installment: 'Tek Çekim',
        installment: 'Taksit',
        installment_count: 'Taksit Sayısı',
        addresses: 'Adresler',
        full_name: 'Ad Soyad',
        card_number: 'Kart Numarası',
        month: 'Ay',
        year: 'Yıl',
        card_cvv: 'Cvv',
        stock_code: 'Stok Kodu',
        order_code: 'Sipariş Kodu',
        gender: 'Cinsiyet',
        birthday: 'Doğum Tarihi',
        blood_group: 'Kan Grubu',
        health_problems: 'Sağlık Problemleri',
        using_pills: 'Sürekli Kullandığı İlaçlar',
        allergy: 'Alerjisi',
        is_profile_share: 'Kişisel bilgilerini paylaş?',
        is_profile_share_full: 'Fotoğraf, video, vb. bilgilerimin sosyal medyada paylaşılmasına izin veriyorum.',
        api_key: 'Api Key',
        api_secret: 'Api Secret Key',
        campaigns: 'Kampanyalar',
        total_product: 'Ürün Toplamı',
        discount_total: 'İndirim Toplamı',
        total: 'Toplam',
        payment_at: 'Ödeme Tarihi',
        payment_bank: 'Ödeme Yapilan Banka',
        payment_message: 'Ödeme Mesaji',
        status: 'Durum',
        statuses: 'Durumlar',
        loyalty: 'Bağlılık',
        birthday_country: 'Doğum Yeri (Ülke)',
        birthday_city: 'Doğum Yeri (Şehir)',
        type: 'Tür',
        types: 'Türler',
        draft: 'Taslak',
        drafts: 'Taslaklar',
        contract_address: 'Sözleşme Adresi',
        invoice_address: 'Fatura Adresi',
        global_search: 'öğrenci ad, soyad, telefon numarası veya eposta ile ara...',
        parent_name: 'Veli',
        school: 'Okul',
        upload_photo: 'Fotoğraf Tükle',
        active_education_year: 'Aktif Eğitim Yılı',
        sibling: 'Kardeş',
        success_rate: 'Başarı Oranı',
        offer: 'Teklif',
        order: 'Sipariş',
        source: 'Kaynak',
        schemas: 'Şemalar',
        hook: 'Bağlantı Noktası',
        sources: 'Kaynaklar',
        date: 'Tarih',
        delivery_service: 'Kargo Firması',
        delivery_code: 'Kargo Takip Kodu',
    },

    // Misc
    or: 'Veya',
    yes: 'Evet',
    no: 'Hayır',
    free: 'Ücretsiz',
    welcome: 'Hoş geldiniz!',
    page_header_search: 'sayfa, blog, ürünler, s.s.s. arasında arama yap...',
    create_offer: 'Teklif Oluştur',
    create_interview: 'Görüşme Oluştur',
    create_bulk_message: 'Toplu Mesaj Gönder',
    shop_title: 'Market',
    pagination_result: 'Toplam <b>{total}</b> sonuç bulundu.',

    // Buttons
    btn_login: 'Giriş Yap!',
    btn_register: 'Kayıt Ol',
    btn_send: 'Gönder',
    btn_save: 'Kaydet',
    btn_update: 'Güncelle',
    btn_change: 'Değiştir',
    btn_edit: 'Düzenle',
    btn_delete: 'Sil',
    btn_cancel: 'İptal',
    btn_back: 'Geri Dön',
    btn_upload: 'Yükle',
    btn_create: 'Oluştur',
    btn_next: 'Devam Et',
    btn_re_calculate: 'Yeniden Hesapla',
    btn_search: 'Ara',
    btn_buy: 'Satın Al',
    btn_add: 'Ekle',
    btn_pay: 'Ödeme Yap',
    btn_select: 'Seç',
    btn_print: 'Yazdır',
    btn_print_plain: 'Ödeme Planını Yazdır',
    btn_print_contract: 'Sözleşme Yazdır',
};
