<template>
    <form-box :title="$t('cms.seo.file.show')" back_link="/cms/seo/files" :store="update" :loading="loading">
        <el-form ref="form" :model="form">
            <el-form-item label="Baslik">
                <el-input v-model="form.title" placeholder="Baslik"/>
            </el-form-item>

            <el-form-item label="Dosya Adi">
                <el-input v-model="form.name" placeholder="Dosya Adi"/>
            </el-form-item>

            <el-form-item label="Dosya Icerigi">
                <el-input v-model="form.content" placeholder="Dosya Icerigi" type="textarea" rows="20"/>
            </el-form-item>
        </el-form>
    </form-box>
</template>

<script>
import {email, maxLength, minLength, required} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";
import {GET_CRUD_ITEM, UPDATE_CRUD_ITEM} from "@/store/actions.type";

export default {
    name: 'CrudShow',
    data() {
        return {
            path: 'cms/seo/files',
            pageLoading: false,
            loading: false,
            submitted: false,
            form: {
                //
            },
        }
    },
    validations: {
        form: {
            //
        }
    },
    computed: mapGetters(['ui', 'item']),
    mounted() {
        this.show();
    },
    methods: {
        show() {
            this.pageLoading = true;

            this.$store
                .dispatch(GET_CRUD_ITEM, {path: this.path, id: this.$route.params.id})
                .then(() => {
                    this.pageLoading = false;
                    this.form = this.cloneObjectData(this.item(this.storePath(this.path)));
                })
                .catch(() => {
                    this.pageLoading = false;
                })
        },
        update() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;
            this.form.path = this.path

            this.$store
                .dispatch(UPDATE_CRUD_ITEM, this.form)
                .then(() => {
                    this.submitted = false;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.submitted = false;
                });
        }
    }
}
</script>
