import PageLayout from "@/app/Layouts/PageLayout";
import ViewContainer from "@/app/Containers/ViewContainer";

// Categories
import CategoryIndex from "@/app/Pages/Cms/Categories/Index";
import CategoryCrud from "@/app/Pages/Cms/Categories/Crud";

import ContentIndex from "@/app/Pages/Cms/Contents/Index";
import ContentCrud from "@/app/Pages/Cms/Contents/Crud";

// Seo
// Links
import LinkIndex from "@/app/Pages/Cms/Seo/Links/Index";
import LinkShow from "@/app/Pages/Cms/Seo/Links/Show";

// Files
import FileIndex from "@/app/Pages/Cms/Seo/Files/Index";
import FileCreate from "@/app/Pages/Cms/Seo/Files/Create";
import FileShow from "@/app/Pages/Cms/Seo/Files/Show";

// Redirects
import RedirectIndex from "@/app/Pages/Cms/Seo/Redirects/Index";
import RedirectCreate from "@/app/Pages/Cms/Seo/Redirects/Create";
import RedirectShow from "@/app/Pages/Cms/Seo/Redirects/Show";

export default {
    path: '/cms',
    component: PageLayout,
    meta: {
        auth: true,
        title: 'İçerikler',
    },
    children: [
        {
            path: 'seo',
            component: ViewContainer,
            meta: {
                auth: true,
                title: 'Seo',
            },
            children: [
                {
                    path: 'links',
                    component: ViewContainer,
                    meta: {
                        auth: true,
                        title: 'Seo Linkler',
                    },
                    children : [
                        {
                            name: 'app.seo.links.index',
                            path: '',
                            component: LinkIndex,
                            meta: {
                                auth: true,
                                title: 'Seo Linkler',
                            },
                        },
                        {
                            name: 'app.seo.links.show',
                            path: ':id',
                            component: LinkShow,
                            meta: {
                                auth: true,
                                title: 'Seo Link Bilgileri',
                            },
                        }
                    ]
                },
                {
                    path: 'files',
                    component: ViewContainer,
                    meta: {
                        auth: true,
                        title: 'Seo Dosyaları',
                    },
                    children : [
                        {
                            name: 'app.seo.files.index',
                            path: '',
                            component: FileIndex,
                            meta: {
                                auth: true,
                                title: 'Seo Dosyaları',
                            },
                        },
                        {
                            name: 'app.seo.files.create',
                            path: 'create',
                            component: FileCreate,
                            meta: {
                                auth: true,
                                title: 'Dosya Oluştur',
                            },
                        },
                        {
                            name: 'app.seo.files.show',
                            path: ':id',
                            component: FileShow,
                            meta: {
                                auth: true,
                                title: 'Dosya Bilgileri',
                            },
                        }
                    ]
                },
                {
                    path: 'redirects',
                    component: ViewContainer,
                    meta: {
                        auth: true,
                        title: '301 Yönlendirmeleri',
                    },
                    children : [
                        {
                            name: 'app.seo.redirects.index',
                            path: '',
                            component: RedirectIndex,
                            meta: {
                                auth: true,
                                title: '301 Yönlendirmeleri',
                            },
                        },
                        {
                            name: 'app.seo.redirects.create',
                            path: 'create',
                            component: RedirectCreate,
                            meta: {
                                auth: true,
                                title: 'Yönlendirme Oluştur',
                            },
                        },
                        {
                            name: 'app.seo.redirects.show',
                            path: ':id',
                            component: RedirectShow,
                            meta: {
                                auth: true,
                                title: 'Yönlendirme Bilgileri',
                            },
                        }
                    ]
                },
            ]
        },
        {
            path: 'pages',
            component: ViewContainer,
            meta: {
                auth: true,
                title: 'Sayfalar',
            },
            children: [
                {
                    path: 'categories',
                    component: ViewContainer,
                    meta: {
                        auth: true,
                        title: 'Kategoriler',
                    },
                    children : [
                        {
                            name: 'app.cms.pages.categories.index',
                            path: '',
                            component: CategoryIndex,
                            meta: {
                                auth: true,
                                title: 'Kategoriler',
                                alias : 'pages'
                            },
                        },
                        {
                            name: 'app.cms.pages.categories.create',
                            path: 'create',
                            component: CategoryCrud,
                            meta: {
                                auth: true,
                                title: 'Kategori Oluştur',
                                alias : 'pages'
                            },
                        },
                        {
                            name: 'app.cms.pages.categories.show',
                            path: ':id',
                            component: CategoryCrud,
                            meta: {
                                auth: true,
                                title: 'Kategori Bilgileri',
                                alias : 'pages'
                            },
                        }
                    ]
                },
                {
                    path: 'contents',
                    component: ViewContainer,
                    meta: {
                        auth: true,
                        title: 'İçerikler',
                    },
                    children : [
                        {
                            name: 'app.cms.pages.contents.index',
                            path: '',
                            component: ContentIndex,
                            meta: {
                                auth: true,
                                title: 'İçerikler',
                                alias : 'pages'
                            },
                        },
                        {
                            name: 'app.cms.pages.contents.create',
                            path: 'create',
                            component: ContentCrud,
                            meta: {
                                auth: true,
                                title: 'İçerik Oluştur',
                                alias : 'pages'
                            },
                        },
                        {
                            name: 'app.cms.pages.contents.show',
                            path: ':id',
                            component: ContentCrud,
                            meta: {
                                auth: true,
                                title: 'İçerik Bilgileri',
                                alias : 'pages'
                            },
                        }
                    ]
                },
            ]
        },
        {
            path: 'posts',
            component: ViewContainer,
            meta: {
                auth: true,
                title: 'Blog',
            },
            children: [
                {
                    path: 'categories',
                    component: ViewContainer,
                    meta: {
                        auth: true,
                        title: 'Kategoriler',
                    },
                    children : [
                        {
                            name: 'app.cms.posts.categories.index',
                            path: '',
                            component: CategoryIndex,
                            meta: {
                                auth: true,
                                title: 'Kategoriler',
                                alias : 'posts',
                            },
                        },
                        {
                            name: 'app.cms.posts.categories.create',
                            path: 'create',
                            component: CategoryCrud,
                            meta: {
                                auth: true,
                                title: 'Kategori Oluştur',
                                alias : 'posts',
                            },
                        },
                        {
                            name: 'app.cms.posts.categories.show',
                            path: ':id',
                            component: CategoryCrud,
                            meta: {
                                auth: true,
                                title: 'Kategori Bilgileri',
                                alias : 'posts',
                            },
                        }
                    ]
                },
                {
                    path: 'contents',
                    component: ViewContainer,
                    meta: {
                        auth: true,
                        title: 'İçerikler',
                    },
                    children : [
                        {
                            name: 'app.cms.posts.contents.index',
                            path: '',
                            component: ContentIndex,
                            meta: {
                                auth: true,
                                title: 'İçerikler',
                                alias : 'posts',
                            },
                        },
                        {
                            name: 'app.cms.posts.contents.create',
                            path: 'create',
                            component: ContentCrud,
                            meta: {
                                auth: true,
                                title: 'İçerik Oluştur',
                                alias : 'posts',
                            },
                        },
                        {
                            name: 'app.cms.posts.contents.show',
                            path: ':id',
                            component: ContentCrud,
                            meta: {
                                auth: true,
                                title: 'İçerik Bilgileri',
                                alias : 'posts',
                            },
                        }
                    ]
                },
            ]
        },
        {
            path: 'faqs',
            component: ViewContainer,
            meta: {
                auth: true,
                title: 'Sayfalar',
            },
            children: [
                {
                    path: 'categories',
                    component: ViewContainer,
                    meta: {
                        auth: true,
                        title: 'Kategoriler',
                    },
                    children : [
                        {
                            name: 'app.cms.faqs.categories.index',
                            path: '',
                            component: CategoryIndex,
                            meta: {
                                auth: true,
                                title: 'Kategoriler',
                                alias : 'faqs'
                            },
                        },
                        {
                            name: 'app.cms.faqs.categories.create',
                            path: 'create',
                            component: CategoryCrud,
                            meta: {
                                auth: true,
                                title: 'Kategori Oluştur',
                                alias : 'faqs'
                            },
                        },
                        {
                            name: 'app.cms.faqs.categories.show',
                            path: ':id',
                            component: CategoryCrud,
                            meta: {
                                auth: true,
                                title: 'Kategori Bilgileri',
                                alias : 'faqs'
                            },
                        }
                    ]
                },
                {
                    path: 'contents',
                    component: ViewContainer,
                    meta: {
                        auth: true,
                        title: 'İçerikler',
                    },
                    children : [
                        {
                            name: 'app.cms.faqs.contents.index',
                            path: '',
                            component: ContentIndex,
                            meta: {
                                auth: true,
                                title: 'İçerikler',
                                alias : 'faqs'
                            },
                        },
                        {
                            name: 'app.cms.faqs.contents.create',
                            path: 'create',
                            component: ContentCrud,
                            meta: {
                                auth: true,
                                title: 'İçerik Oluştur',
                                alias : 'faqs'
                            },
                        },
                        {
                            name: 'app.cms.faqs.contents.show',
                            path: ':id',
                            component: ContentCrud,
                            meta: {
                                auth: true,
                                title: 'İçerik Bilgileri',
                                alias : 'faqs'
                            },
                        }
                    ]
                },
            ]
        },
        {
            path: 'references',
            component: ViewContainer,
            meta: {
                auth: true,
                title: 'Referanslar',
            },
            children: [
                {
                    path: 'categories',
                    component: ViewContainer,
                    meta: {
                        auth: true,
                        title: 'Kategoriler',
                    },
                    children : [
                        {
                            name: 'app.cms.references.categories.index',
                            path: '',
                            component: CategoryIndex,
                            meta: {
                                auth: true,
                                title: 'Kategoriler',
                                alias : 'references'
                            },
                        },
                        {
                            name: 'app.cms.references.categories.create',
                            path: 'create',
                            component: CategoryCrud,
                            meta: {
                                auth: true,
                                title: 'Kategori Oluştur',
                                alias : 'references'
                            },
                        },
                        {
                            name: 'app.cms.references.categories.show',
                            path: ':id',
                            component: CategoryCrud,
                            meta: {
                                auth: true,
                                title: 'Kategori Bilgileri',
                                alias : 'references'
                            },
                        }
                    ]
                },
                {
                    path: 'contents',
                    component: ViewContainer,
                    meta: {
                        auth: true,
                        title: 'İçerikler',
                    },
                    children : [
                        {
                            name: 'app.cms.references.contents.index',
                            path: '',
                            component: ContentIndex,
                            meta: {
                                auth: true,
                                title: 'İçerikler',
                                alias : 'references'
                            },
                        },
                        {
                            name: 'app.cms.references.contents.create',
                            path: 'create',
                            component: ContentCrud,
                            meta: {
                                auth: true,
                                title: 'İçerik Oluştur',
                                alias : 'references'
                            },
                        },
                        {
                            name: 'app.cms.references.contents.show',
                            path: ':id',
                            component: ContentCrud,
                            meta: {
                                auth: true,
                                title: 'İçerik Bilgileri',
                                alias : 'references'
                            },
                        }
                    ]
                },
            ]
        },
        {
            path: 'events',
            component: ViewContainer,
            meta: {
                auth: true,
                title: 'Sayfalar',
            },
            children: [
                {
                    path: 'categories',
                    component: ViewContainer,
                    meta: {
                        auth: true,
                        title: 'Kategoriler',
                    },
                    children : [
                        {
                            name: 'app.cms.events.categories.index',
                            path: '',
                            component: CategoryIndex,
                            meta: {
                                auth: true,
                                title: 'Kategoriler',
                                alias : 'events'
                            },
                        },
                        {
                            name: 'app.cms.events.categories.create',
                            path: 'create',
                            component: CategoryCrud,
                            meta: {
                                auth: true,
                                title: 'Kategori Oluştur',
                                alias : 'events'
                            },
                        },
                        {
                            name: 'app.cms.events.categories.show',
                            path: ':id',
                            component: CategoryCrud,
                            meta: {
                                auth: true,
                                title: 'Kategori Bilgileri',
                                alias : 'events'
                            },
                        }
                    ]
                },
                {
                    path: 'contents',
                    component: ViewContainer,
                    meta: {
                        auth: true,
                        title: 'İçerikler',
                    },
                    children : [
                        {
                            name: 'app.cms.events.contents.index',
                            path: '',
                            component: ContentIndex,
                            meta: {
                                auth: true,
                                title: 'İçerikler',
                                alias : 'events'
                            },
                        },
                        {
                            name: 'app.cms.events.contents.create',
                            path: 'create',
                            component: ContentCrud,
                            meta: {
                                auth: true,
                                title: 'İçerik Oluştur',
                                alias : 'events'
                            },
                        },
                        {
                            name: 'app.cms.events.contents.show',
                            path: ':id',
                            component: ContentCrud,
                            meta: {
                                auth: true,
                                title: 'İçerik Bilgileri',
                                alias : 'events'
                            },
                        }
                    ]
                },
            ]
        },
    ]
}
