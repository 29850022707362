<template>
    <div>
        <tinymce v-model="container[model]" :api-key="token" :init="config" />
        <el-dialog width="90%" title="Dosya Yöneticisi" :visible.sync="fileManagerDialogVisible" append-to-body class="file-manager-dialog">
            <template slot="title">
                <div class="head">
                    <div class="title">Dosya Yöneticisi</div>
                    <div class="action">
                        <el-button type="primary" @click="handleSelected">
                            Dosyayı Seç
                        </el-button>
                    </div>
                </div>
            </template>
            <el-alert type="info" :closable="false">
                Web sitenizin daha hızlı açılması için, resimlerinizin boyutunu küçültmeyi ve .webp formatına çevirmeyi unutmayınız.
            </el-alert>
            <file-manager :settings="fileManagerSettings" />
        </el-dialog>
    </div>
</template>

<script>
import tinymce from '@tinymce/tinymce-vue'

export default {
    name: 'Editor',
    props: ['container', 'model', 'small', 'mini'],
    components: {
        tinymce
    },
    data() {
        return {
            token: 'dg1ket0we411dr2auynqqca8pd7pwuh027udqarr7vlm8h2m',
            config: {
                verify_html: false,
                entity_encoding: 'raw',
                extended_valid_elements: '*[*]',
                height: 400,
                menubar: false,
                forced_root_block: false,
                paste_as_text: true,
                plugins: 'print paste preview importcss tinydrive searchreplace autolink autosave code save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount textpattern noneditable help charmap quickbars emoticons',
                toolbar: 'code fullscreen preview | bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor | table | image media',

                image_title: true,
                automatic_uploads: true,
                file_picker_types: 'image',
                file_picker_callback: (callback) => {
                    this.fileManagerDialogVisible = true;
                    this.fileManagerDialogCallback = callback;
                }
            },

            fileManagerDialogVisible: false,
            fileManagerDialogCallback: null
        }
    },
    created() {

        this.config.height = 400;

        if (this.small) {
            this.config.height = 200;
        }

        if (this.mini) {
            this.config.height = 120;
        }
    },
    methods: {
        handleSelected() {
            let files = this.$store.state.fm.left.selected.files;

            if (!this.lodash.size(files)) {
                return this.$notify
                    .error({
                        title: 'Upss!',
                        message: 'Dosya seçimi yapmanız gerekiyor!',
                        position: 'top-right'
                    });
            }

            this.fileManagerDialogCallback(this.publicFilePath('storage/' + files[0]));
            this.fileManagerDialogVisible = false;
        }
    }
};
</script>

<style>
.tox {
    z-index : 9999 !important;
}

.tox-fullscreen .tox.tox-tinymce.tox-fullscreen {
    z-index : 99999 !important;
}

.file-manager-dialog {
    z-index : 999999 !important;
}
</style>
