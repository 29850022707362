<template>
    <div :class="$route.meta.bodyClass" v-if="ui">
        <header id="Header" v-if="$route.meta.layout !== 'content'">
            <div class="header">
                <el-container>
                    <div class="header-wrp">
                        <div class="items">
                            <el-popover placement="bottom-start" width="270" trigger="hover" popper-class="dropdown-menu dark account bottom" append-to-body>
                                <div class="item icon cursor-pointer" slot="reference">
                                    <icon source="office-building-cog-outline" />
                                </div>
                                <div class="body">
                                    <div class="head is-menu">
                                        <div class="media">
                                            <icon source="office-building-cog-outline" />
                                        </div>
                                        <div class="text">
                                            <div class="title">{{ $t('menu.system') }}</div>
                                        </div>
                                    </div>
                                    <router-link to="/system/settings" class="link-item">
                                        <icon source="tune" class="media" />
                                        {{ $t('menu.settings') }}
                                    </router-link>
                                    <router-link to="/design/theme" class="link-item">
                                        <icon source="compare" class="media" />
                                        {{ $t('menu.themes') }}
                                    </router-link>
                                    <router-link to="/design/menus" class="link-item">
                                        <icon source="dots-horizontal-circle-outline" class="media" />
                                        {{ $t('menu.menus') }}
                                    </router-link>
                                    <router-link to="/system/users" class="link-item">
                                        <icon source="account-multiple" class="media" />
                                        {{ $t('menu.users') }}
                                    </router-link>
                                    <router-link to="/system/exports" class="link-item">
                                        <icon source="database-export-outline" class="media" />
                                        {{ $t('menu.excel_exports') }}
                                    </router-link>
                                </div>
                            </el-popover>

                            <el-popover placement="bottom-start" width="270" trigger="hover" popper-class="dropdown-menu dark account bottom" append-to-body>
                                <div class="item icon cursor-pointer" slot="reference">
                                    <icon source="briefcase-outline"/>
                                </div>
                                <div class="body">
                                    <div class="head is-menu">
                                        <div class="media">
                                            <icon source="briefcase-outline"/>
                                        </div>
                                        <div class="text">
                                            <div class="title">{{ $t('menu.seo') }}</div>
                                        </div>
                                    </div>
                                    <router-link to="/cms/seo/links" class="link-item">
                                        <icon source="link-variant" class="media" />
                                        {{ $t('menu.seo_links') }}
                                    </router-link>
                                    <router-link to="/cms/seo/files" class="link-item">
                                        <icon source="file-document-multiple-outline" class="media" />
                                        {{ $t('menu.seo_files') }}
                                    </router-link>
                                    <router-link to="/cms/seo/redirects" class="link-item">
                                        <icon source="page-next-outline" class="media" />
                                        {{ $t('menu.seo_redirects') }}
                                    </router-link>
                                </div>
                            </el-popover>

                            <el-tooltip :content="$t('menu.forms')" placement="bottom">
                                <router-link to="/tools/form-messages" class="item icon">
                                    <icon source="form-select" />
                                </router-link>
                            </el-tooltip>

                            <SearchHeader />
                        </div>
                        <div class="items">
                            <el-tooltip :content="$t('menu.file_manager')" placement="bottom">
                                <router-link to="/tools/file-manager" class="item icon">
                                    <icon source="folder-multiple-image" />
                                </router-link>
                            </el-tooltip>
                            <el-tooltip :content="$t('menu.plugins')" placement="bottom">
                                <router-link to="/plugins" class="item icon">
                                    <icon source="rocket-outline" />
                                </router-link>
                            </el-tooltip>
                            <ActivityHeader />
                        </div>
                    </div>
                </el-container>
            </div>
        </header>

        <main id="Main" v-if="$route.meta.layout !== 'content'">
            <aside id="Sidebar">
                <div class="head">
                    <router-link to="/dashboard" class="logo">
                        <img src="/img/sidebar-logo.svg" alt="logo">
                    </router-link>
                </div>
                <MenuSidebar />
                <div class="footer">
                    <div class="account">
                        <Account placement="right-end" />
                    </div>
                </div>
            </aside>
            <el-container>
                <div class="main-wrp">
                    <vue-page-transition>
                        <router-view />
                    </vue-page-transition>
                </div>
            </el-container>
        </main>

        <vue-page-transition v-if="$route.meta.layout === 'content'">
            <router-view />
        </vue-page-transition>

        <FileManager v-if="$store.state.account" />
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import SearchHeader from "@/app/Layouts/Parts/Search";
import ActivityHeader from "@/app/Layouts/Parts/Activity";
import Account from "@/app/Layouts/Parts/Account";
import MenuSidebar from "@/app/Layouts/Parts/Menu";
import FileManager from "@/app/Layouts/Parts/FileManager";

export default {
    name: 'PageLayout',
    components: {
        MenuSidebar,
        SearchHeader,
        Account,
        ActivityHeader,
        FileManager
    },
    computed: mapGetters(['ui', 'account']),
}
</script>
